import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconSettings from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/settings.tsx";

export function MatchingPanel({ data, select, color }){

	const hasData = !!data;
	
	let body = null;
	if(hasData){
		body = (
			<div>{data.missing > 0?
				<span class="text-danger py-2" onClick={select}>{data.missing} correspondances manquantes</span>
				:null}
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	let link = null;
	if(false){
		link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3" onClick={select}>voir le référentiel <IconChevronRight class="w-6 h-6 ms-2" /></button>;
	}else{
		link = <a type="button" href="le-referentiel" class="btn bg-danger text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Voir le référentiel <IconChevronRight class="w-6 h-6 ms-2" /></a>;
	}

	return (
		<div class="bg-white" style="margin-bottom: -24px;">
			<div class="my-4 py-4 container">
				<h2 class="h5 d-flex align-items-center justify-content-left titleb">
					<span>Le paramétrage</span>
				</h2>
				<div class="card shadow">
					<div class="card-body my-2 px-4">
						<div>
							<div class="row">
								<div class="col-12 col-sm-4 d-flex align-items-center justify-content-center">
									<img
										src="/img/nirio-illustrations-Parcours client 1.png"
										class="w-64 h-32"
										alt="accounting"
										/>
								</div>
								<div class="col">
									<div>
										<div class="h4 titleb">
											<img style="width:20px" class="d-inline-block me-2" src="icons/Valeurs_Securite.svg" />
											<span class="align-middle">Le référentiel de consolidation</span></div>
										<div class="my-2">Renseignez votre référentiel de familles et comptes comptables pour vos exports et votre suivi d'activité.</div>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<div class="fs-6 text-black-50">{body}</div>
										{link}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="my-4 py-4"></div>
			</div>
		</div>
	);
}
