import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import { getServer } from '../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import { ResponsiveContainer, XAxis, Tooltip,
	BarChart, Bar,
} from 'recharts';

export function ActivityPanel({ data, select, color }){
	
	const hasData = !!data;
	
	let body = null;
	if(hasData){

		let list = [];
		let chLabels = [];
		//let chLegends = [];
		let chData = [];

		let link = null;
		if(false){
			link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3" onClick={select}>voir plus <IconChevronRight class="w-6 h-6 ms-2" /></button>;
		}else{
			link = <a type="button" href="/mes-chiffres" class="btn bg-danger text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Voir plus <IconChevronRight class="w-6 h-6 ms-2" /></a>;
		}

		body = (
			<div>
				<div class="row mb-4">
					<div class="col-12 col-sm-4 order-sm-2">
						{data && data.list?
						<div width="262px" height="130px" style="position:relative;height: 100%">
							{data.list.length > 0 ?
								<BarChart width={262} height={130} data={data.list}>
									<Bar dataKey="ca" fill="rgba(153, 102, 255, 0.4)" radius={[10, 10, 0, 0]} barSize={10} />{/* barCategoryGap="0"  barSize={10} barGap="1%" */}
									<XAxis dataKey="day" axisLine={true} strokeWidth={0.3} tickLine={false} style={{fontSize: '0.7rem'}} />
									<Tooltip cursor={{ fill: "rgb(204 204 204 / 75%)" }} />
								</BarChart>
								: <p style="top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute;" >Pas assez de données disponibles</p>
								}
								</div>
								:<div>
									<div class="spinner-border text-secondary" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</div>
							}
					</div>
					<div class="col">
						<div class="h4 titleb">
							<img style="width:20px" class="d-inline-block me-2" src="icons/Actions_Piloter_Activites.svg" />
							<span class="align-middle">Les derniers chiffres de votre activité</span></div>
						<div class="mt-2 mb-4">Les données de vos caisses se synchronisent après chaque clôture de service.</div>
						<div class="d-flex">
							{link}
						</div>
					</div>
				</div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div class="b3">
		<div class="mt-4 py-4 container">
			<h4 class="titleb">L'Activité</h4>
			<div class="card shadow">
				<div class="card-body mx-2 px-4">
					{body}
				</div>
			</div>
		</div>
		</div>
	);
}
