import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconLogout from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/logout.tsx";

export function AccountPanel({ data, select, color }){

	const hasData = !!data;
	
	let body = null;
	if(hasData){
		
		body = (
			<div class="gbg">
				<div class="p-4 container">
				<div class="d-flex align-items-center justify-content-left">
					<div class="bg-white rounded p-2">
						<img style="width: 70px" src="icons/male.svg" />
					</div>
					<div class="ms-3 text-white">
						<div class="ms-2 h2 mb-0">
							{data.shopname}
						</div>
						<div class="ms-2 fs-6">{data.activity}</div>
					</div>
				</div></div>
				<div class="bfooter" style={{borderBottomColor: color}}></div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div class="">
			{body}
		</div>
	);
}