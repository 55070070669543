import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx";

export function AccountingPanel({ data, select, color }){

	const hasData = !!data;
	
	let body = null;
	if(hasData){

		let link = null;
		if(false){
			link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3" onClick={select}>voir les documents <IconChevronRight class="w-6 h-6 ms-2" /></button>;
		}else{
			link = <a type="button" href="la-compta" class="btn bg-danger text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Voir les documents <IconChevronRight class="w-6 h-6 ms-2" /></a>;
		}

		body = (
			<div>
				<div class="row">
					<div class="col-12 col-sm-4 order-sm-2 d-flex align-items-center justify-content-center">
						<img
							src="/img/nirio-illustrations-Simplicite.png"
							class="w-64 h-32"
							alt="accounting"
							/>
					</div>
					<div class="col">
						<div>
							<div class="h4 titleb">
							<img style="width:20px" class="d-inline-block me-2" src="icons/Valeurs_Simplicite.svg" />
							<span class="align-middle">Libérez-vous de la lourdeur administrative</span></div>
							<div class="my-2">Retrouvez vos documents comptables d'activité, de TVA et autres.</div>
						</div>
						<div class="row">
							<div class="col d-flex align-items-center justify-content-start">{link}</div>
							<div class="col d-flex align-items-center justify-content-end"><div class="fs-6 text-black-50">{data.daysToExport}j depuis le dernier export</div></div>
						</div>
					</div>
				</div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div class="mt-4 mb-2 py-4 container">
			<h2 class="h5 d-flex align-items-center justify-content-left titleb">
				<span>La comptabilité</span>
			</h2>
			<div class="card shadow">
				<div class="card-body my-2 px-4">
					{body}
				</div>
			</div>
		</div>
	);
}