import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../components/SessionContext.tsx";
import { ActivityPanel } from "../components/Activity/Panel.tsx";
import { ActivityContent } from "../components/Activity/Content.tsx";
import { MessagingPanel } from "../components/Messaging/Panel.tsx";
import { MessagingContent } from "../components/Messaging/Content.tsx";
import { KPIPanel } from "../components/KPI/Panel.tsx";
import { KPIContent } from "../components/KPI/Content.tsx";
import { AccountingPanel } from "../components/Accounting/Panel.tsx";
import { AccountingContent } from "../components/Accounting/Content.tsx";
import { MarketingPanel } from "../components/Marketing/Panel.tsx";
import { MarketingContent } from "../components/Marketing/Content.tsx";
import { AccountPanel } from "../components/Account/Panel.tsx";
import { AccountContent } from "../components/Account/Content.tsx";
import { InvoicingPanel } from "../components/Invoicing/Panel.tsx";
import { InvoicingContent } from "../components/Invoicing/Content.tsx";
import { MatchingPanel } from "../components/Matching/Panel.tsx";
import { MatchingContent } from "../components/Matching/Content.tsx";
import { OnboardingPanel } from "../components/Onboarding/Panel.tsx";
import { ProgressiveRequest } from "../components/ProgressiveRequest.tsx";
import { getServer } from '../components/Server.tsx';
import IconUserCircle from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/user-circle.tsx";
import IconDice1 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-1.tsx";
import IconDice4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-4.tsx";
import IconDice5 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-5.tsx";
import IconBuildingStore from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/building-store.tsx";
import { getColorEnabled } from "https://deno.land/std@0.140.0/fmt/colors.ts";
import { Footer } from "../components/Footer/Panel.tsx";
import { Header } from "../components/Header/Panel.tsx";

interface LoginProps {
	uuid: string;
	date: string;
	onSigningIn(session?: string): any;
}

function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

export default function FrontApp() {
	const [session, setSession] = useState(null);

	return (
		<div>
			<SessionContext.Provider value={session}>
				{session?
					<div>
						<Front />
					</div>:
					<div>
						<Login onSigningIn={(s)=>setSession(s)}/>
					</div>
				}
			</SessionContext.Provider>
		</div>
	);
}

function Login(props: LoginProps) {
	const [init, setInit] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");

	useEffect(()=>{

		if(!init){
			
			ProgressiveRequest({
				url: getServer()+'/api/front/login',
				method: 'post',
				data: {
					action: 'precheck'
				},
				receiving: function(data){
					
					if(data.session){
						props.onSigningIn(data.session);
						redirectIfNeeded();
					}

					setInit(true);

				},
				received: function(){
					//console.log('done');
				}
			});

			
		}

	}, [init]);

	function SignIn(service, email="", password=""){
		
		ProgressiveRequest({
			url: getServer()+'/api/front/login',
			method: 'post',
			data: {
				action: 'signin',
				service: service,
				email: email,
				password: password,
			},
			receiving: function(data){

				if(data.session){
					props.onSigningIn(data.session);
					redirectIfNeeded();
				} else {
					setMessage(data.message);
				}
				
			},
			received: function(){
				//console.log('done');
			}
		});
	}

	function redirectIfNeeded(){

		// if get arg "goto"
		var qs = getQueryParams(window.location.search);

		if(qs['goto']){
			window.location.assign(qs['goto']);
		}

	}

	if(!init){
		return (
			<div class="my-4">
				<div class="my-4">
					<div class="d-flex justify-content-center align-items-center my-4">
						<div class="spinner-border text-secondary" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}else{
		return (
			<div class="mx-2">
				<div class="d-flex justify-content-center my-3">
					<h1 class="d-flex align-items-center h4 my-4">
						<IconBuildingStore class="w-6 h-6" /><span class="ms-2">Se connecter</span>
					</h1>
				</div>
				<div class="card my-2" onClick={() => SignIn('fdj')}>
					<div class="card-body">
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="d-flex align-items-center my-2"><IconDice5 class="w-6 h-6" /><span class="ms-2">FDJ</span></h2>
							<IconUserCircle class="w-6 h-6" />
						</div>
					</div>
				</div>
				<div class="card my-2" onClick={() => SignIn('aleda')}>
					<div class="card-body">
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="d-flex align-items-center my-2"><IconDice1 class="w-6 h-6" /><span class="ms-2">Aleda</span></h2>
							<IconUserCircle class="w-6 h-6" />
						</div>
					</div>
				</div>
				<div class="card my-2" onClick={() => SignIn('laddition')}>
					<div class="card-body">
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="d-flex align-items-center my-2"><IconDice4 class="w-6 h-6" /><span class="ms-2">L'Addition</span></h2>
							<IconUserCircle class="w-6 h-6" />
						</div>
					</div>
				</div>
				<div class="card my-2">
					<div class="card-body">
						<div class="d-flex align-items-center justify-content-center">
							<form id="log" style="text-align: center; margin: auto;" onSubmit={(e) => {e.preventDefault();SignIn('laddition', email, password); return false;}}>
								<IconUserCircle class="w-6 h-6 m-auto mt-3" /><br />
								{ message != "" ? <div class="alert alert-warning">{message}</div> : "" }
								<div class="my-2">
									<div class="my-2"><input type="email" class="finput" placeholder="Email" value={email} onChange={(ev)=>{setEmail(ev.target.value);}} required/></div>
									<div class="my-2"><input type="password" class="finput" placeholder="Mot de passe" value={password} onChange={(ev)=>{setPassword(ev.target.value);}} required/></div>
								</div>
								<div class="my-4">
									<input type="submit" class="btn arrowb text-white rounded-pill d-flex align-items-center btn-sm px-4 m-auto" value="Valider"/>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="my-4 py-4"></div>
			</div>
		);
	}
}

class Front extends Component {
	static contextType = SessionContext;

	state = {
		loading: true,
		items: {},
		content: null
	};

	_state = {
		loading: true,
		items: {},
		content: null
	};

	customSetState(o){
		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;

		//Update colors
		let nwitems = o.items;
		for(let k in nwitems){
			let color = this.getColor(Object.entries(nwitems).findIndex(e => e[0] == k), nwitems);
			nwitems[k].ncolor = color;
		}
		this.setState({
			items: nwitems
		});
	}

	componentDidMount() {
		let session = this.context;
		let that = this;

		ProgressiveRequest({
			url: getServer()+'/api/front/home',
			method: 'post',
			data: {
				action: 'dashboard',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				if(data.init){
					that.customSetState({
						items: data.items
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.items){
						nwItems[prop] = that._state.items[prop];
					}
					for(let prop in data.items){
						nwItems[prop] = data.items[prop];
					}
					that.customSetState({
						items: nwItems
					});
				}

			},
			received: function(){
				console.log('done');
			}
		});
	}

	displayContent = function(identifier, args){
		this.setState({content: {identifier, args}});
	}

	//Function to retreive the next bloc color to have the right footer border color
	getColor = function(k, nitems){
		let items = Object.entries(nitems);
		return k == (items.length-1) ? 'rgb(40 46 135)' : (
			items[k+1][1].data/* && items[k+1][1].data.items > 0 && items[k+1][1].data.items.length > 0 */?
			items[k+1][1].color
			: this.getColor(k+1, nitems)
		)
	}
	
	render() {
		let that = this;

		if(this.state.content){
			//content
			return (
				<div>
					<div>{dashboardContents[this.state.content.identifier].view({back: function(){that.setState({content: null});},args: this.state.content.args})}</div>
				</div>
			);
			
		}else{
			// dashboard
			let list = [];
			if(this.state.items){
				for(let k in this.state.items){
					console.log('k:'+k);
					let item = this.state.items[k];
					console.log(item);
					let color = that.getColor(Object.entries(this.state.items).findIndex(e => e[0] == k), this.state.items);
					item.ncolor = color;
					list.push(<div key={"item-"+k}>{dashboardPanels[k]({data: item.data, select: (function(_k, _item){return function(args){that.displayContent(_k, args);}})(k, item), color: item.ncolor})}</div>);
				}
			}

			return (
				<div>
					<div>{list}</div>
				</div>
			)
		}
	}
}

let dashboardPanels = {
	header: Header,
	onboarding: OnboardingPanel,
	account: AccountPanel,
	messaging: MessagingPanel,
	kpi: KPIPanel,
	activity: ActivityPanel,
	matching: MatchingPanel,
	marketing: MarketingPanel,
	accounting: AccountingPanel,
	invoicing: InvoicingPanel
};

let dashboardContents = {
	account: {view: AccountContent},
	messaging: {view: MessagingContent},
	kpi: {view: KPIContent},
	activity: {view: ActivityContent},
	accounting: {view: AccountingContent},
	matching: {view: MatchingContent},
	marketing: {view: MarketingContent},
	invoicing: {view: InvoicingContent}
};
