import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import IconUser from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/user.tsx";
import { getServer } from '../Server.tsx';
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";

export function Header({ data, select, color }){
	const hasData = !!data;
	
	let disconnect = function(){
		
		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'disconnect'
			},
			receiving: function(data){
				window.location.reload();
			},
			received: function(){
				console.log('done');
			}
		});

	};

	return (
		<nav class="navbar navbar-expand-lg bg-body-tertiary header">
			<div class="container">
				<a href="/" class="me-4"><img style="width:100px;" src="niriow.png" /></a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarNavDropdown">
					<ul class="navbar-nav me-auto">
						<li class="nav-item py-2 py-lg-0">
							<a href="/mes-chiffres" class="me-4">Mon activité</a>
						</li>
						<li class="nav-item py-2 py-lg-0">
							<a href="/la-compta" class="me-4">Ma comptabilité</a>
						</li>
						<li class="nav-item py-2 py-lg-0">
							<a href="/le-referentiel" class="me-4">Mes paramètres</a>
						</li>
					</ul>
					<div class="d-flex">
						<ul class="navbar-nav py-3 py-lg-0">
							<li class="nav-item py-2 py-lg-0 navbar-text">
								<a href="#" class="me-4 fw-smaller" onClick={(ev)=>{disconnect();ev.preventDefault();return false;}}>Déconnexion</a>
							</li>
							<li class="nav-item py-2 py-lg-0 fw-bolder">
								<a href="/mon-compte" class="me-4 d-flex"><span class="me-2">{hasData?data.contact:''}</span><IconUser class="w-5 h-5" /></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}

/*

		<div class="header bg-white d-flex justify-content-around align-items-center p-1">
			
			
			<a href="/la-compta">Ma comptabilité</a>
			<a href="/le-referentiel">Mes paramètres</a>
			<a href="/mon-compte">Mon profil</a>
		</div>

*/