import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";

export function MarketingPanel({ data, select, color }){
	
	const hasData = !!data;
	
	let body = null;
	if(hasData){

		let link = null;
		if(false){
			link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3" onClick={select}>voir le catalogue <IconChevronRight class="w-6 h-6 ms-2" /></button>;
		}else{
			link = <a type="button" href="mes-services" style="background:rgb(41 45 135)" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Voir tous les services disponibles <IconChevronRight class="w-6 h-6 ms-2" /></a>;
		}

		body = (
			<div>
				<div class="row text-white">
					<div class="col-12 col-sm-4 d-flex align-items-center justify-content-center">
						<div class="rounded-2 p-2 mb-2" style="background:rgb(128 220 255)">
							<img
								src="/img/nirio-illustrations-Detaillant.png"
								class="w-64 h-32"
								alt="marketing"
								/>
						</div>
					</div>
					<div class="col">
						<div class="mb-4">
							<div class="h4 titleb">
							<img style="width:20px" class="d-inline-block me-2" src="icons/Transactions_Virement_Bancaire.svg" />
							<span class="align-middle">Développez votre business</span></div>
							<div class="my-2">En ajoutant de nouveaux services à votre portefeuille, complétez votre offre et augmentez vos marges.</div>
						</div>
					</div>
				</div>
				<div class="row my-3">
					<div class="col">
						<div class="card shadow">
							<div class="card-body">
								<div>
									<div class="mb-2">
										<div class="mt-2 h5">Financez votre BFR en quelques clics</div>
										<div class="mt-2 mb-3">À partir de vos données d'encaissement, nous faisons à tout moment une simulation en temps réel de votre capacité de financement de votre besoin en fond de roulement.</div>
										<div class="d-flex justify-content-between">
											<a type="button" href="#" style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-4 pe-3" onClick={()=>{}}>je veux voir ma simulation <i class="fa-regular fa-circle-down ms-2"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="card shadow">
							<div class="card-body">
								<div>
									<div class="mb-2">
										<div class="mt-2 h5">La solution de paiement dédiée aux commerces de proximité</div>
										<div class="mt-2 mb-3">Nirio Pay propose une offre unique de moyens de paiement physiques et virtuels unifiée pour les bureaux de tabac et restaurants. Suivez tous vos flux et proposez à vos clients les parcours de paiement les plus modernes.</div>
										<div class="d-flex justify-content-between">
											<a type="button" href="#" style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-4 pe-3" onClick={()=>{}}>essayer <i class="fa-regular fa-circle-down ms-2"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col d-flex align-items-center justify-content-end">{link}</div>
				</div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div class="b3">
		<div class="/mt-2 mb-4 py-4 container">
			<div class="card bg-infohighlight shadow">
				<div class="card-body my-2 px-4">
					{body}
				</div>
			</div>
		</div>
				<div class="bfooter" style={{borderBottomColor: color}}></div>
		</div>
	);
}