import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import { AccountShopContent, AccountManagerContent, AccountTagContent } from "../Account/Content.tsx";

export function OnboardingPanel({ data, select }){
	const [step, setStep] = useState(0);

	const hasData = !!data;

	let confirm = () => {
		//set on boarding to 1 and redirect home
		ProgressiveRequest({
			url: getServer()+'/api/front/messaging',
			method: 'post',
			data: {
				action: 'done'
			},
			receiving: function(data){
			},
			received: function(){
				window.location.reload();
			}
		});
	};
	
	let body = null;
	if(hasData){
		body = (
			<div>
				{step == 0?
					<div>
						<AccountShopContent editOnly={true} validate={function(){setStep(1);}} />
					</div>
					:(step == 1?
						<div>
							<AccountManagerContent editOnly={true} validate={function(){setStep(2);}} />
						</div>
						:<div>
							<AccountTagContent editOnly={true} validate={function(){confirm();}} />
						</div>
					)
				}
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div class="p-4 container">
			<h2 class="h4">Configuration</h2>
			{body}
			<div class="stepper">
				<li><a href="#"	class={"w-6 h-6 text-center rounded-circle text-white text-decoration-none "+(step > -1?'arrowb':'bg-secondary')} onClick={()=>{setStep(0)}}>1</a></li>
				<li><a href="#" class={"w-6 h-6 text-center rounded-circle text-white text-decoration-none "+(step > 0?'arrowb':'bg-secondary')} onClick={()=>{setStep(1)}}>2</a></li>
				<li><a  href="#" class={"w-6 h-6 text-center rounded-circle text-white text-decoration-none "+(step > 1?'arrowb':'bg-secondary')} onClick={()=>{setStep(2)}}>3</a></li>
			</div>
		</div>
	);
}
