import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import { MESSAGE_LEVEL } from "../../back/inc/dao/constants.ts";

export function MessagingPanel({ data, select, color }){
	const hasData = !!data;

	function seeMore(m){
		select({message: {id: m.message.id}});
	}

	function markAsRead(m){
		ProgressiveRequest({
			url: getServer()+'/api/front/messaging',
			method: 'post',
			data: {
				action: 'seen',
				id: m.message.id
			},
			receiving: function(data){
			},
			received: function(){
				data.messages.splice(data.messages.indexOf(m.message), 1)
			}
		});
	}
	
	return (hasData && data.messages.length > 0 ? (
		<div class="my-4">
			{data.messages.length > 0 && data.messages.map((message)=>{

				let link = null;
				if(message.detail){
					if(false){
						link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3" onClick={()=>{seeMore({message: message});}}>{message.detail.button} <IconChevronRight class="w-6 h-6 ms-2" /></button>;
					}else{
						link = <a type="button" href={"/message/"+message.id} class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3">{message.detail.button} <IconChevronRight class="w-6 h-6 ms-2" /></a>;
					}
				}else{
					link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{markAsRead({message: message});}}>Ok, j'ai compris</button>;
				}
				
				return <div class="my-2">
					<div class={"card text-white bg-"+(message.level == MESSAGE_LEVEL.warning?"warning":(message.level == MESSAGE_LEVEL.critical?"danger":"info"))+'subtle'}>
						<div class="card-body">
							<div>{message.body}</div>
							{message.detail?
								<div class="d-flex align-items-center justify-content-between mt-2">
									<span></span>
									{link}
								</div>
								:<div class="d-flex align-items-center justify-content-between mt-2">
									<span></span>
									<button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{markAsRead({message: message});}}>Ok, j'ai compris</button>
								</div>
							}
						</div>
					</div>
				</div>;
			})}
		</div>
	) : <></>);
}
