import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import { PieChart, Pie, Cell, Sector, ResponsiveContainer, Label, RadialBarChart, RadialBar, Tooltip, PolarAngleAxis, Legend, BarChart, Bar, XAxis, CartesianGrid, YAxis, ReferenceArea } from "recharts";

export function KPIPanel({ data, select, color }){

	const hasData = !!data;

	let body = null;
	if(hasData){

		body = (
			<div>
				<div>{data.nb} comptes</div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '20%'}}>&nbsp;</div>
			</div>
		);
	}
	
	let link = null;
	if(false){
		link = <div class="card mt-2" onClick={select}>
				<div class="card-body">
					<div class="d-flex align-items-center justify-content-between">
						<div class="d-flex align-items-center"><span class="ms-2">Consulter tous les indicateurs de votre activité</span></div>
						<IconChevronRight class="w-6 h-6" />
					</div>
				</div>
			</div>;
	}else{
		link = <a class="card mt-2 text-dark text-decoration-none" href="/mes-indicateurs">
			<div class="card-body">
				<div class="d-flex align-items-center justify-content-between titleb">
					<div class="d-flex align-items-center"><span class="ms-2"><b>Consulter tous les indicateurs de votre activité</b></span></div>
					<IconChevronRight class="w-6 h-6" />
				</div>
			</div>
		</a>;
	}

	  const goal = 15000;
	  const [dayData, setDayData] = useState([]);
	  const [weekData, setWeekData] = useState([]);

	  function getWeekNumber(d) {
		// Copy date so don't modify original
		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		// Set to nearest Thursday: current date + 4 - current day number
		// Make Sunday's day number 7
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
		// Get first day of year
		var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
		// Calculate full weeks to nearest Thursday
		var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
		// Return array of year and week number
		return [d.getUTCFullYear(), weekNo];
	}

	const wdays = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	  
	  useEffect(() => {
		if (data && data.list) {
			setWeekData(data.list.week);
			let ndata = [];
			for (let i=0;i<data.list.day.length/2;i++){
				let e = data.list.day[i];//console.log(e);
				let n = data.list.day[i+7];
				ndata.push({
					name: wdays[new Date(e.date).getDay()],
					last: e.ca,
					next: n.ca,
				});
			}
			setDayData(ndata);
		}
		return () => {};
	  }, [data]);

	return (
		<div class="b2">
			<div class="my-4 py-4 container">
				<div class="my-3">
					<div class="row">
						<div class="col d-flex align-items-center justify-content-center">
							{dayData.length>0?
								<div width="400px" height="250px">
									<BarChart
										width={400}
										height={250}
										data={dayData}
										margin={{
											top: 5,
											right: 0,
											left: 0,
											bottom: 5,
										}}
										>
										<XAxis dataKey="name" axisLine={false} tickLine={false} />
										<Tooltip />
										<Legend />
										<Bar dataKey="last" fill="#e1d0f9" name="Semaine dernière" 
											radius={[1, 1, 0, 0]}/>
										<Bar dataKey="next" fill="#eff9d0" name="Cette semaine" 
											radius={[1, 1, 0, 0]}/>
									</BarChart>
								</div>
								:<p class="bg-info text-white border-0 rounded-pill p-4" style="width: max-content">Pas assez de données</p>
							}
						</div>
						<div class="col d-flex align-items-center justify-content-center">
							{weekData.length>0?
								<div width="300px" height="200px" style={{color: 'white'}}>
									<BarChart
										data={weekData}
										height={200}
										width={300}
										>
										<XAxis
											axisLine={false}
											dataKey="name"
											tick
											tickLine={false}
											style={{
												fontSize: '0.7rem',
												fill: 'white'
											}}
										/>
										<Tooltip />
										<Bar
											dataKey="ca"
											radius={[3, 3, 0, 0]}
										/>
									</BarChart>
								</div>
								:<p class="bg-info text-white border-0 rounded-pill p-4" style="width: max-content">Pas assez de données</p>
							}
						</div>
					</div>
				</div>
				<div class="my-3">{link}</div>
			</div>
			<div class="bfooterr" style={{borderBottomColor: color}}></div>
		</div>
	);
}
