import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";

export function InvoicingPanel({ data, select, color }){

	const hasData = !!data;
	
	let body = null;
	if(hasData){

		let link = null;
		if(false){
			link = <button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3" onClick={select}>voir les factures <IconChevronRight class="w-6 h-6 ms-2" /></button>;
		}else{
			link = <a type="button" href="/mes-factures" style="background:rgb(41 45 135)" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">En savoir plus <IconChevronRight class="w-6 h-6 ms-2" /></a>;
		}
		
		body = (
			<div>
				<div class="row">
					<div class="col-12 col-sm-4 d-flex align-items-center justify-content-center">
						<img
							src="/img/nirio-illustrations-Parcours client pro.png"
							class="w-64 h-32"
							alt="invoicing"
							/>
					</div>
					<div class="col">
						<div>
							<div class="h4 titleb">
							<img style="width:20px" class="d-inline-block me-2" src="icons/Transactions_Historique_Paiements_Facture.svg" />
							<span class="align-middle">Vos achats dans le cloud</span></div>
							<div class="my-2">Déposez vos factures, elles seront lues, classées et exportées vers la compta.</div>
						</div>
						<div class="row">
							<div class="col d-flex align-items-center justify-content-start"><div class="fs-6 text-black-50">{data.nbInvoices} factures disponibles</div></div>
							<div class="col d-flex align-items-center justify-content-end">{link}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div>
		<div class="mt-2 mb-4 py-4 container">
			<div class="card shadow">
				<div class="card-body my-2 px-4">
					{body}
				</div>
			</div>
		</div>
		<div class="bfooterr" style={{borderBottomColor: color}}></div>
		</div>
	);
}